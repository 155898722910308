<template>
  <div class="case-main-box">
<!--    <FormTitle :infoName="'PPT模板'" />-->
    <!-- 类型 -->
    <div class="hot-search-box search-pc-show">
      <div class="tit">模板类型：</div>
      <ul>
        <li :class="{'active': queryInfo.tagIds.length <= 0}" @click="searchBtn()">全部</li>
        <li :class="{'active': queryInfo.tagIds.includes(item.id)}"
            v-for="item in searchData"
            @click="searchBtn(item)">
          {{item.name}}
        </li>
      </ul>
    </div>
    <div class="hot-search-box2 search-h5-show">
      <div class="tit" @click="searchDrawer = true">
        热门搜索
        <i class="el-icon-arrow-right"></i>
      </div>
      <el-drawer
        title="热门搜索"
        custom-class="search-h5-box"
        :visible.sync="searchDrawer"
        :direction="'rtl'">
        <ul class="search-h5-ul">
          <li :class="{'active': queryInfo.tagIds.length <= 0}" @click="searchBtn()">全部</li>
          <li :class="{'active': queryInfo.tagIds.includes(item.id)}"
              v-for="item in searchData"
              @click="searchBtn(item)">{{item.name}}</li>
        </ul>
      </el-drawer>
    </div>
    <!-- 内容列表 -->
    <div class="case-content" v-if="productList && productList.length > 0">
      <div class="list-box" @mouseenter="mouseEnter(index)" @mouseleave="mouseLeave(index)" v-for="(item,index) in productList">
        <div class="list-icon">
          <img src="@/assets/images/icon/icon-ppt.png" alt="" />
        </div>
        <img class="list-img" :src="item.image" alt="" />
        <div class="list-title">
          <div class="list-name">{{item.title}}</div>
          <div class="show-btn2">
            <div class="e-btn" @click="previewBtn(item)">
              <i class="el-icon-zoom-in"></i>
              <span>预览</span>
            </div>
            <div class="e-btn btn-pc-show" @click="downloadBtn(item)">
              <i class="el-icon-download"></i>
              <span>下载</span>
            </div>
            <div class="e-btn btn-h5-show" @click="downloadBtn2(item)">
              <i class="el-icon-download"></i>
              <span>下载</span>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div v-else>
      <el-empty description="没有搜索到结果"></el-empty>
    </div>

    <div class="search-pc-show">
      <el-pagination v-show="total > 0"
                     class="page-pagination"
                     background
                     :page-sizes="[20, 50, 100]"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page.sync="queryInfo.pageNum"
                     layout="sizes, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </div>
    <div class="search-h5-show">
      <el-pagination v-show="total > 0"
                     class="page-pagination"
                     :pager-count="5"
                     small
                     background
                     @current-change="handleCurrentChange"
                     layout="prev, pager, next"
                     :page-size="queryInfo.pageSize"
                     :total="total">
      </el-pagination>
    </div>

    <el-dialog :title="'《'+dialog.title+'》'"
               :visible.sync="centerDialogVisible"
               :modal-append-to-body="false"
               class="dialog-box"
               @close="closePreview"
               :destroy-on-close="true"
               center>
      <div class="dialog-img">
        <img v-if="dialog.url" :src="dialog.url" class="avatar" alt="">
      </div>
      <div class="dialog-btn dialog-pc-show">
        <el-button type="primary" @click="openDownload('pc')">下 载</el-button>
      </div>
      <div class="dialog-btn dialog-h5-show">
        <el-button type="primary" @click="openDownload('h5')">下 载</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="'《'+dialog.title+'》'"
               :visible.sync="dialogFreeDownload"
               :modal-append-to-body="false"
               class="free-dialog"
               @close="closeFreeDownload"
               :destroy-on-close="true"
               center>
      <div class="free-txt">
        <div class="free-title">
          <i class="el-icon-wallet"></i>
          <span>下载权益</span>
        </div>
        <div class="free-cont">
          <p class="top">今日免费下载次数剩余 <span>{{ downloadNum }}</span> 次</p>
          <p>(会员每日可下载 <span>{{ todayTotalNums.todayPptDownloadTotalNum }}</span> 次)</p>
        </div>
        <div class="free-btn">
          <el-button @click="dialogFreeDownload = false">取 消</el-button>
          <el-button type="primary" @click="openFreeDownload">确认下载</el-button>
        </div>
      </div>
    </el-dialog>

    <!--    <DownloadH5 ref="Download" @downloadOff="downloadOff"/>-->
    <DownloadWay ref="DownloadWay"
                 @downloadDeduction="downloadDeduction"
                 @restartTimer="restartTimer"
                 @userSetInfo="getuserSetInfo"
                 @downloadOnWay="downloadOnWay"
                 @downloadOff="downloadOff"
                 @downloadOffWay="downloadOffWay"/>

  </div>
</template>
<script>
import PptApi from '@/api/pptTemplate'
import CommonApi from "@/api/common"
import generateQRCode from '@/components/generateQRCode/generateQRCode.vue'
// import DownloadH5 from '@/components/downloadH5/downloadH5.vue'
import DownloadWay from "@/components/downloadWay/index.vue"
import UserPromotApi from "@/api/user"
import {EventBus} from "@/utils/eventBus"
import FormTitle from "@/components/FormTitle/index.vue"
import RequestlogApi from "@/api/requestlog"

export default {
  name: 'PptLibrary',
  components: {FormTitle, DownloadWay, generateQRCode },
  props: ['transferMent','infoName'],
  data() {
    return {
      queryInfo: {
        pageNum: 1,
        pageSize: 20,
        tagIds: []
      },
      input3: '',
      select: '',
      showTag: false,
      searchData: [],
      productList: [],
      total: 0,//列表总数据条数
      count: 10,
      loading: false,
      centerDialogVisible: false, //预览
      dialog: {
        title: '',
        url: ''
      },
      orderDetail: '',
      searchDrawer: false,
      showH5: false,
      storageItem: '',
      userInfo: {
        isLogin: false,
        username: '',
        nickname: '',
        token: ''
      },
      userSetInfo: '',
      pptOrderId: '',
      todayTotalNums: '',
      dialogFreeDownload: false
    }
  },
  created() {
    if(localStorage.getItem('userInfo')){
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    }
    if(localStorage.getItem('userSetInfo')){
      this.userSetInfo = JSON.parse(localStorage.getItem('userSetInfo'))
    }
    this.report()
  },
  mounted() {
    let _this = this
    _this.getPptTypeList()
    _this.searchBtn()
    EventBus.$on("userLogin", (msg) => {
      // 登录成功发送来的消息
      if(msg === 'userLoginTrue'){
        _this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
        _this.userSetInfo = JSON.parse(localStorage.getItem('userSetInfo'))
        // 登录后重新打开下载弹窗
        if(_this.pptOrderId){
          _this.reopenDetailVo()
        }
      }
    })
    EventBus.$on('userSetInfo', (msg) => {
      if(msg === 'userInfoTrue'){
        _this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
        _this.userSetInfo = JSON.parse(localStorage.getItem('userSetInfo'))
      }
    })
    EventBus.$on("userOutLogin", (msg) => {
      // 退出登录发送来的消息
      if(msg === 'userOutLoginTrue'){
        _this.userInfo = _this.$options.data().userInfo
        _this.userSetInfo = ''
      }
    })
  },
  beforeDestroy() {
    //清除定时器查询
    clearInterval(this.timer);
  },
  methods: {
    // 上报日志
    report(){
      let data = {
        type: 4,
        channelCode: '',
        description: ''
      }
      let time = new Date().getTime()
      let key = localStorage.getItem('key')
      let ticket = localStorage.getItem('ticket')
      let setOf = ticket + time + ticket + ticket + time + ticket
      let infoMd5 = this.$md5(setOf)
      RequestlogApi.report(data,key,time,infoMd5).then(res => {
        // console.log(res.data)
      }).catch(() => {
      })
    },
    getuserSetInfo(msg){
      if(msg){
        // this.getUserDetailVo()
        this.userSetInfo = JSON.parse(localStorage.getItem('userSetInfo'))
      }
    },
    // 登录后重新打开下载弹窗
    reopenDetailVo(){
      let _this = this
      // 清除定时器 关闭弹窗
      _this.$refs.DownloadWay.handleClose()
      // 重新打开弹窗
      // console.log(_this.pptOrderId)
      setTimeout(()=>{
        let item = {
          title: _this.dialog.title,
          id: _this.pptOrderId
        }
        _this.downloadBtn(item)
      },200)
    },
    searchBtn(item){
      if (item) {
        const index = this.queryInfo.tagIds.indexOf(item.id);
        if (index === -1) {
          this.queryInfo.tagIds.push(item.id)
        } else {
          this.queryInfo.tagIds.splice(index, 1);
        }
      } else {
        this.queryInfo.tagIds = []
      }
      this.queryInfo.pageNum = 1
      this.search()
      this.searchDrawer = false
    },
    // 鼠标移入移出事件
    mouseEnter(index){
      this.productList[index].showTag = true
    },
    mouseLeave(index){
      this.productList[index].showTag = false
    },
    // 查询获取
    search(){
      PptApi.page(this.queryInfo).then(res => {
        if(res.data.total > 0){
          res.data.data.forEach(item => {
            item.showTag = false
          })
          this.productList = res.data.data
          this.total = res.data.total
        }else {
          this.productList = []
        }
      }).catch(() => {
      })
    },
    // 获取预览
    preview(item){
      let time = new Date().getTime()
      let key = localStorage.getItem('key')
      let ticket = localStorage.getItem('ticket')
      let setOf = ticket + time + ticket + ticket + time + ticket
      let infoMd5 = this.$md5(setOf)
      PptApi.preview({pptId:item.id},key,time,infoMd5).then(res => {
        this.dialog.url = res.data
        this.centerDialogVisible = true
      }).catch(() => {
      })
    },
    // 获取下载
    postCreateOrder(){
      let _this = this;
      let time = new Date().getTime()
      let key = localStorage.getItem('key')
      let ticket = localStorage.getItem('ticket')
      let setOf = ticket + time + ticket + ticket + time + ticket
      let infoMd5 = this.$md5(setOf)
      let data = {
        pptId: _this.pptOrderId,
      }
      if(localStorage.getItem('shareCode')){
        data.shareCode = localStorage.getItem('shareCode')
      }
      PptApi.postCreateOrder(data,key,time,infoMd5).then(res => {
        _this.orderDetail = res.data
        // 移动端 H5 适配
        if(_this.showH5){
          let params = {
            show: true,
            title: '下载 《' + _this.dialog.title + '》',
            info: _this.orderDetail
          }
          // _this.$refs.Download.showDrawer(params)
          _this.$refs.DownloadWay.showDrawer(params)
          _this.getTimeFun(_this.orderDetail.id)
        }else {
          let params = {
            show: true,
            title: '下载 《' + _this.dialog.title + '》',
            info: _this.orderDetail
          }
          _this.$refs.DownloadWay.showDrawer(params)
          _this.getTimeFun(_this.orderDetail.id)
        }
      }).catch(() => {
      })
    },
    // 查看详情按钮
    previewBtn(item){
      this.storageItem = ''
      this.storageItem = item
      this.dialog.title = item.title
      this.preview(item)
    },
    // 关闭预览
    closePreview(){
      // console.log('关闭了预览')
      // this.dialog.title = ''
      this.dialog.url = ''
    },
    // 下载按钮
    downloadBtn(item){
      let _this = this
      _this.dialog.title = item.title
      _this.pptOrderId = item.id
      if(_this.userSetInfo && _this.userSetInfo.vipInfoVos.length){
        let result = _this.userSetInfo.vipInfoVos.some(item => 'vipCode' in item && item.vipCode.includes('PPT_VIP'))
        if(result){
          _this.getVipDownloadInfo()
        }else {
          _this.postCreateOrder()
        }
      }else {
        _this.postCreateOrder()
      }
    },
    // 下载按钮 h5
    downloadBtn2(item){
      let _this = this
      _this.showH5 = true
      _this.dialog.title = item.title
      _this.pptOrderId = item.id
      if(_this.userSetInfo && _this.userSetInfo.vipInfoVos.length){
        let result = _this.userSetInfo.vipInfoVos.some(item => 'vipCode' in item && item.vipCode.includes('PPT_VIP'))
        if(result){
          _this.getVipDownloadInfo()
        }else {
          _this.postCreateOrder()
        }
      }else {
        _this.postCreateOrder()
      }
    },
    // 关闭弹窗 清除定时器 还原状态
    downloadOff(info){
      this.showH5 = info.showState
      if(!info.payState){
        clearInterval(this.timer)
      }
    },
    // 清除定时器回调
    downloadOffWay(info){
      if(info.close){
        clearInterval(this.timer)
      }
    },
    // 支付成功回调
    downloadOnWay(info){
      if(info.payState){
        this.getQueryPayState(info.id)
      }
    },
    // 勾选抵扣的回调
    downloadDeduction(info){
      let _this = this
      if(info.state){
        _this.postQueryOrderDetail()
      }
    },
    // 重启定时器
    restartTimer(info){
      let _this = this
      if(info.close){
        _this.getTimeFun(_this.orderDetail.id)
      }
    },
    // 查询订单支付状态
    getQueryPayState(orderId){
      let _this = this
      CommonApi.queryPayState(orderId).then(res => {
        if(res.data){
          // 清除定时器查询
          clearInterval(_this.timer)
          // 关闭弹窗
          _this.$refs.DownloadWay.handleClose()
          _this.getuserSetInfo(true)
          //主动调用下载接口
          _this.getDownLink(orderId)
        }
      }).catch(() => {
      })
    },
    // 下载接口
    getDownLink(orderId){
      let _this = this
      let time = new Date().getTime()
      let key = localStorage.getItem('key')
      let ticket = localStorage.getItem('ticket')
      let setOf = ticket + time + ticket + ticket + time + ticket
      let infoMd5 = _this.$md5(setOf)
      CommonApi.getDownLink(orderId,key,time,infoMd5).then(res => {
        window.location.href = res.data;
      }).catch(() => {
      })
    },
    // 定时器查询订单状态
    getTimeFun(id){
      this.timer = setInterval(() => {
        this.getQueryPayState(id)
      }, 3000);
    },
    // 获取ppt模板类型
    getPptTypeList() {
      this.searchData = []
      PptApi.pptTypeList().then(res => {
        this.searchData.push(...res.data)
      })
    },
    // 修改每页条数
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.queryInfo.pageNum = 1
      this.search()
    },
    // 修改页码
    // 修改每页条数
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
    },
    // 关闭预览 并 打开下载弹窗
    openDownload(type){
      let _this = this;
      _this.centerDialogVisible = false
      let dataList = {
        title: _this.storageItem.title,
        id: _this.storageItem.id
      }
      if(type === 'pc'){
        _this.downloadBtn(dataList)
      }else if(type === 'h5'){
        _this.downloadBtn2(dataList)
      }
    },
    // 查询订单信息
    postQueryOrderDetail() {
      let _this = this
      CommonApi.queryOrderDetail(_this.orderDetail.id).then(res => {
        _this.orderDetail = res.data
        // 如果支付金额为 0 显示支付按钮
        // _this.payBtn = Number(_this.orderDetail.price) === 0;
        let params = {
          show: true,
          title: '下载 《' + _this.dialog.title + '》',
          info: _this.orderDetail
        }
        _this.$refs.DownloadWay.showDrawer(params)
        _this.getTimeFun(_this.orderDetail.id)
      }).catch(() => {
      })
    },
    // 获取Vip下载次数信息
    getVipDownloadInfo(){
      let _this = this
      UserPromotApi.getVipDownloadInfo(_this.userInfo.token).then(res => {
        // console.log(res.data)
        _this.todayTotalNums = res.data
        if(this.downloadNum > 0){
          setTimeout(()=>{
            _this.dialogFreeDownload = true
          },100)
        }else {
          _this.$message.warning('今日免费下载次数已用完')
          setTimeout(()=>{
            _this.postCreateOrder()
          },100)
        }
        // todayCaseDownloadTotalNum	今日案例可下载免费总次数
        // todayPptDownloadTotalNum	今日PPT可下载免费总次数
        // todayUseCaseDownloadNum	今日已使用案例免费下载次数
        // todayUsePptDownloadNum	今日已使用PPT免费下载次数
      }).catch(() => {
      })
    },
    // vip下载 打开
    openFreeDownload(){
      this.getVipDownLink(this.pptOrderId)
    },
    // vip下载 关闭
    closeFreeDownload(){
      this.dialogFreeDownload = false
    },
    // vip下载接口
    getVipDownLink(orderId){
      let _this = this
      let time = new Date().getTime()
      let key = localStorage.getItem('key')
      let ticket = localStorage.getItem('ticket')
      let setOf = ticket + time + ticket + ticket + time + ticket
      let infoMd5 = _this.$md5(setOf)
      PptApi.getVipDownLink(orderId,_this.userInfo.token,key,time,infoMd5).then(res => {
        _this.$message.success('下载成功')
        _this.closeFreeDownload()
        window.location.href = res.data
      }).catch(() => {
      })
    }
  },
  computed: {
    noMore () {
      return this.count >= 20
    },
    disabled () {
      return this.loading || this.noMore
    },
    downloadNum(){
      let t = Number(this.todayTotalNums.todayPptDownloadTotalNum)
      let u = Number(this.todayTotalNums.todayUsePptDownloadNum)
      return t - u
    }
  }
}
</script>
<style scoped lang="less">
.case-main-box{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 20px 20px 0 20px;
  .hot-search-box{
    width: 100%;
    min-height: 60px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    .tit{
      width: 80px;
      margin-top: 5px;
      font-size: 14px;
      font-weight: bold;
    }
    ul{
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      li{
        padding: 0 20px;
        height: 30px;
        line-height: 30px;
        margin-right: 20px;
        margin-bottom: 14px;
        border-radius: 30px;
        color: #101010;
        cursor: pointer;
        background-color: #ffffff;
        box-shadow: 0 0 5px #e4e4e4;
      }
      li.active{
        color: #ffffff;
        background-color: #409EFF;
      }
      li:hover{
        color: #ffffff;
        background-color: #8cd9fa;
      }
    }
  }
  .search-pc-show{
    //display: block;
  }
  .search-h5-show{
    display: none;
  }
  .case-content{
    max-width: 1350px;
    margin: 0 auto;
    padding: 20px 0 50px 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    .list-box{
      position: relative;
      width: 240px;
      height: 180px;
      margin: 0 30px 30px 0;
      box-sizing: border-box;
      border: 2px solid #ffffff;
      border-radius: 15px;
      box-shadow: 0 0 8px rgba(5, 102, 188, 0.1);
      cursor: pointer;
      overflow: hidden;
      //.fade-enter-active, .fade-leave-active {
      //  transition: opacity .5s;
      //}
      //.fade-enter, .fade-leave-to{
      //  opacity: 0;
      //}
      .show-btn2{
        width: 100%;
        border-radius: 15px;
        margin-top: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        .e-btn{
          padding: 5px 12px;
          border-radius: 5px;
          color: #409EFF;
          background-color: #ffffff;
          border: 1px solid #d7d7d7;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          i{
            font-size: 14px !important;
          }
          span{
            margin-left: 5px;
            font-size: 12px;
          }
        }
        .e-btn:hover{
          color: #ffffff;
          background-color: #7bb6fd;
          border: 1px solid #7bb6fd;
        }
        .e-btn:last-child{
          color: #ffffff;
          background-color: #409EFF;
          border: 1px solid #409EFF;
        }
        .e-btn:last-child:hover{
          background-color: #7bb6fd;
          border: 1px solid #7bb6fd;
        }
        .btn-pc-show{
          //display: block;
        }
        .btn-h5-show{
          display: none;
        }
      }
      .list-title{
        width: 100%;
        padding: 0 10px;
        text-align: center;
        background-color: rgba(255, 255, 255, 0.8);
        transition: transform 0.5s ease-in-out;
        transform: translateY(0px);
        .list-name{
          line-height: 42px;
          color: #101010;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .list-img{
        width: 100%;
        height: 132px;
        border: 0;
        border-radius: 15px 15px 0 0;
        //margin-top: 42px;
      }
      .list-icon{
        position: absolute;
        z-index: 10;
        top: 10px;
        right: 10px;
        width: 20px;
        height: 20px;
        img{
          width: 100%;
          height: 100%;
          border: 0;
        }
      }
    }
    .list-box::after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 40px;
      border-radius: 15px 15px 0 0;
      z-index: 9;
      background: linear-gradient(180deg, rgba(0,0,0,0.5),rgba(0,0,0,0));
    }
    .list-box:hover{
      .list-title{
        transform: translateY(-44px);
      }
    }
  }

  .dialog-box{
    /deep/ .el-dialog{
      width: 720px;
      min-width: 720px !important;
      height: 90vh;
      margin-top: 5vh !important;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      .el-dialog__close{
        font-size: 24px;
      }
      .el-dialog__body{
        height: calc(100% - 54px);
        padding: 0;
      }
    }
    .dialog-img{
      width: 100%;
      height: calc(100% - 50px);
      text-align: center;
      overflow: auto;
      img{
        width: 90%;
        max-width: 768px;
      }
    }
    .dialog-btn{
      height: 50px;
      line-height: 50px;
      text-align: center;
    }
    .dialog-pc-show{display: block}
    .dialog-h5-show{display: none}
  }
  .free-dialog{
    /deep/ .el-dialog{
      width: 540px;
      border-radius: 15px;
      .el-dialog__close{
        font-size: 24px;
      }
    }
    .free-txt{
      width: 440px;
      min-height: 200px;
      margin: 0 auto;
      padding: 20px;
      border: 1px solid #ffeacd;
      border-radius: 15px;
      .free-title{
        i{
          font-size: 18px;
          color: #e6a23c;
        }
        span{
          margin-left: 6px;
          font-size: 16px;
          color: #e6a23c;
        }
      }
      .free-cont{
        padding-top: 34px;
        p{
          text-align: center;
          font-size: 14px;
          letter-spacing: 2px;
          color: #cccccc;
          span{
            color: #3c3c3c;
          }
        }
        p.top{
          padding-bottom: 6px;
          color: #3c3c3c;
          font-size: 16px;
          span{
            font-size: 24px;
            color: #fc5151;
          }
        }
      }
      .free-btn{
        padding-top: 34px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .case-main-box {
    .search-pc-show{
      display: none;
    }
    .search-h5-show{
      display: block;
    }
    .hot-search-box2{
      width: 100%;
      height: 32px;
      line-height: 32px;
      margin: 15px 0;
      border-radius: 5px;
      background-color: #ffffff;
      .tit{
        padding: 0 12px;
        font-size: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }
    .case-content {
      width: 100%;
      padding-top: 0;
      padding-bottom: 30px;
      justify-content: space-between;
      .list-box {
        width: 48%;
        min-width: 124px;
        height: 110px;
        margin: 0 0 20px 0;
        border-radius: 5px;
        .list-icon{
          top: 5px;
          right: 5px;
        }
        .list-img{
          height: 70px;
          border-radius: 5px 5px 0 0;
        }
        .list-title{
          padding: 0;
          margin-top: -3px;
          .list-name{
            width: 100%;
            min-height: 38px;
            padding: 5px;
            margin: 0;
            font-size: 11px;
            line-height: 18px;
          }
          .show-btn2{
            border-radius: 0;
            padding-bottom: 10px;
            .e-btn{
              padding: 0 8px;
              min-height: 24px;
              line-height: 24px;
              i{
                font-size: 12px !important;
              }
              span{
                font-size: 10px;
              }
            }
            .btn-pc-show{
              display: none;
            }
            .btn-h5-show{
              display: flex;
            }
          }
        }
      }
      .list-box::after{
        border-radius: 5px 5px 0 0;
      }
      .list-box:hover{
        .list-title{
          transform: translateY(-36px);
        }
      }
    }
    .dialog-box{
      /deep/ .el-dialog{
        width: 90%;
        min-width: 300px !important;
        .el-dialog__header{
          padding: 20px 30px 10px;
          .el-dialog__title{
            font-size: 14px;
          }
          .el-dialog__headerbtn{
            top: 10px;
            right: 10px;
          }
        }
        .el-dialog__body{
          width: 100%;
          height: calc(100% - 54px);
          padding: 0;
          .dialog-img{
            width: 100%;
          }
        }
      }
      .dialog-pc-show{display: none}
      .dialog-h5-show{display: block}
    }
    .free-dialog{
      /deep/ .el-dialog{
        width: 90%;
        .el-dialog__header{
          padding: 20px 30px 10px;
          .el-dialog__title{
            font-size: 14px;
          }
        }
        .el-dialog__body{
          width: 100%;
          padding: 0 0 10px 0;
        }
      }
      .free-txt{
        width: 94%;
        padding: 15px;
        .free-title{
          i{
            font-size: 14px;
          }
          span{
            font-size: 14px;
          }
        }
        .free-cont{
          padding-top: 24px;
          p{
            font-size: 12px;
            letter-spacing: 1.5px;
          }
          p.top{
            font-size: 14px;
            span{
              font-size: 20px;
            }
          }
        }
        .free-btn{
          padding-top: 24px;
        }
      }
    }
  }
}
</style>
<style lang="less">
.page-pagination{
  padding: 20px 0 50px 0;
  text-align: center;
}
</style>
