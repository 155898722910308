import request from '@/utils/request'

export default {
  // 创建下载订单
  page(data){
    return request({
      url: '/ppt/page',
      method: 'post',
      data: data
    })
  },
  // 创建下载订单
  postCreateOrder(data,key,time,md5){
    return request({
      url: '/ppt/create/order',
      method: 'post',
      headers: {
        "ticket-key": key,
        timestamp: time,
        sgin: md5
      },
      data: data
    })
  },
  // 预览
  preview(data,key,time,md5){
    return request({
      url: '/ppt/preview',
      method: 'post',
      headers: {
        "ticket-key": key,
        timestamp: time,
        sgin: md5
      },
      data: data
    })
  },
  // 类型列表
  pptTypeList(){
    return request({
      url: '/ppt/list',
      method: 'get'
    })
  },
  // 创建vip下载订单
  getVipDownLink(id,token,key,time,md5){
    return request({
      url: '/ppt/getDownLink',
      method: 'post',
      headers: {
        token: token,
        "ticket-key": key,
        timestamp: time,
        sgin: md5
      },
      data: {
        pptId: id
      }
    })
  }
}
